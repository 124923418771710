import { ReactNode, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
    useGetAll360ImagesQuery,
    useGetAll360VideosQuery,
    useGetAllVideos2dQuery,
    useUpdateAllScenarioMediaMutation,
} from "../services/media.service";
import { useCreateGroupHotspotMutation, useUpdateHotspotMutation } from "../services/hotspot.service";
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { BeforeCapture, DragDropContext, DragStart, DragUpdate, DropResult } from "react-beautiful-dnd";
import { IDragOptions } from "@virtus-tech-repository/virtus-tech-repository/lib/models/current.model";
import {
    EDraggableHotspotId,
    EDroppableContainerId,
} from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import {
    setCurrentAttachedHotspot,
    setCurrentAudio,
    setCurrentCreatorScenesTimelineShouldScrollToEnd,
    setCurrentDoubleClick,
    setCurrentDragging,
    setCurrentHotspot,
    setCurrentHotspotTransfer,
    setCurrentPreviewImage,
    setCurrentRichTextEditorPresentationModeDataToDropObject,
    setCurrentToast,
    setCurrentlyPreview,
    setEditHotspotDialogMediaTransfer,
    setHotspotAdded,
    setQuestionAudioAdd,
} from "../store/slices/current.slice";
import ReactGA from "react-ga4";
import { blankHotspot } from "../helpers/hotspotDataGenerator";
import { blankScene } from "../helpers/scenarioDataGenerator";
import { Params, useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useGetScenesQuery, useUpdateSceneMutation, useUpdateScenesOrderMutation } from "../services/scene.service";
import { IScene } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import sleep from "../utils/sleep";
import { moveCursorToEnd } from "../utils/moveCursorToEndRichTextEditor";
import { EMediaFileType, getMediaTypeFromFilePath } from "../utils/acceptedMediaUploadFileExtensions";
import { EContentDataType } from "@virtus-tech-repository/virtus-tech-repository/lib/models/hotspot.model";
import {
    extractUUIDFromPath,
    getImageDimensionsBeforeDrop,
    getPreserveRatioHeightGivenWidth,
    getTextBoxHeightBeforeDrop,
} from "@virtus-tech-repository/virtus-tech-repository";
// @ts-ignore
import shuffleSound from "../assets/sounds/scene_shuffle.mp3";
import { playSoundEffect } from "../utils/playSoundEffect";
import { getVideoDimensionsBeforeDrop } from "@virtus-tech-repository/virtus-tech-repository/lib/utils/getContentDimensionsBeforeDropForRichTextEditorPresentationMode";

export interface IProps {
    children?: ReactNode;
    createScene: any;
    updateScene: any;
    createSceneLoading: any;
    setSceneLoading: (sceneLoading: string) => void;
    createHotspot: any;
    setHotspotLoading: (hotspotLoading: string) => void;
    changeSceneBuffer: any;
    setChangeSceneBuffer: any;
}

export default function DragAndDropWrapper({
    children,
    createScene,
    updateScene,
    createSceneLoading,
    setSceneLoading,
    createHotspot,
    setHotspotLoading,
    changeSceneBuffer,
    setChangeSceneBuffer,
}: IProps) {
    // ## USE SELECTOR ##
    const {
        currentScene,
        currentScenarioId,
        currentlyDragging,
        currentDoubleClick,
        currentQuestion,
        currentHotspot,
        currentHotspotTransfer,
        currentRichTextEditorNormalModeId,
        currentRichTextEditorPresentationModeDropOffsetsCoords,
    } = useAppSelector((state) => state.currentReducer);

    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const { data: immersiveImages } = useGetAll360ImagesQuery(userId);
    const { data: immersiveVideos } = useGetAll360VideosQuery(userId);
    const { data: videos } = useGetAllVideos2dQuery(userId);

    // ## HOOKS ##
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [updateAllScenarioMedia] = useUpdateAllScenarioMediaMutation();
    const [updateScenesOrder] = useUpdateScenesOrderMutation();

    const [createQuestionHotspot] = useCreateGroupHotspotMutation();
    const [updateHotspot] = useUpdateHotspotMutation();
    // const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();

    const { data: scenes } = useGetScenesQuery(currentScenarioId ? currentScenarioId : "");
    // const [updateScene] = useUpdateSceneMutation();

    // ## USE STATE ##

    const [currentDragItemId, setCurrentDragItemId] = useState<string>("");

    useEffect(() => {
        if (currentDoubleClick) {
            if (
                currentDoubleClick.mediaType === EDraggableOptions.IMMERSIVE_IMAGE ||
                currentDoubleClick.mediaType === EDraggableOptions.IMMERSIVE_VIDEO ||
                // currentDoubleClick.mediaType === EDraggableOptions.VIDEO ||
                currentDoubleClick.mediaType === EDraggableOptions.DOCUMENT ||
                currentDoubleClick.mediaType === EDraggableOptions.PASSTHROUGH ||
                currentDoubleClick.mediaType === EDraggableOptions.MODEL_3D
            ) {
                handleMediaTimelineDropped(currentDoubleClick);
                dispatch(setCurrentDoubleClick(undefined));
            } else {
                handleMediaCanvasDropped(currentDoubleClick);
                dispatch(setCurrentDoubleClick(undefined));
            }
        }
    }, [currentDoubleClick]);

    useEffect(() => {
        if (currentHotspotTransfer && currentHotspotTransfer.create) {
            setHotspotLoading("CreateHotspot");
            createHotspot({ sceneId: currentScene.id, hotspot: currentHotspotTransfer.hotspot });
            dispatch(setCurrentAttachedHotspot(currentHotspotTransfer.hotspot));
            dispatch(setHotspotAdded(currentHotspotTransfer.hotspot));
            dispatch(setCurrentHotspotTransfer(undefined));
        }
    }, [currentHotspotTransfer]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    function handleSceneReplace(canvasMedia: IDragOptions) {
        if (!currentScene.id.includes("demo")) {
            setSceneLoading(currentScene.id!);
            if (canvasMedia.mediaType === EDraggableOptions.IMMERSIVE_IMAGE) {
                const draggedImage: IMediaItem | undefined = immersiveImages?.find(
                    (imageFindElement: IMediaItem) => imageFindElement.id === currentDragItemId,
                );

                updateScene({
                    ...currentScene,
                    src: canvasMedia.src,
                    name: draggedImage ? draggedImage.name : currentScene.name,
                    mediaType:
                        currentScene.mediaType === EDraggableOptions.PASSTHROUGH
                            ? EDraggableOptions.PASSTHROUGH
                            : EDraggableOptions.IMMERSIVE_IMAGE,

                    //@ts-ignore
                    startLocation: draggedImage?.startLocation!,
                });
                updateAllScenarioMedia({
                    id: currentScenarioId!,
                    media_type: EDraggableOptions.IMMERSIVE_IMAGE,
                    media_ids: [
                        canvasMedia.src.includes("three_sixty_image")
                            ? canvasMedia.src.split("three_sixty_image/")[1].split(".")[0]
                            : canvasMedia.src.split("app_defaults/")[1].split(".")[0],
                    ],
                });
            } else if (canvasMedia.mediaType === EDraggableOptions.IMMERSIVE_VIDEO) {
                const draggedVideo: IMediaItem | undefined = immersiveVideos?.find(
                    (videoFindElement: IMediaItem) => videoFindElement.id === currentDragItemId,
                );

                console.log("DBG: dragged image", draggedVideo);

                updateScene({
                    ...currentScene,
                    src: canvasMedia.src,
                    name: draggedVideo ? draggedVideo.name : currentScene.name,
                    mediaType: EDraggableOptions.IMMERSIVE_VIDEO,
                    //@ts-ignore
                    startLocation: draggedVideo?.startLocation!,
                });
                updateAllScenarioMedia({
                    id: currentScenarioId!,
                    media_type: EDraggableOptions.IMMERSIVE_VIDEO,
                    media_ids: [canvasMedia.src.split("three_sixty_video/")[1].split(".")[0].split("/")[0]],
                });

                if (currentScene.mediaType === EDraggableOptions.IMMERSIVE_VIDEO) {
                    dispatch(
                        setCurrentToast({
                            id: "VideoReplace",
                            message: "New Video, Stop points might not be in the right location",
                        }),
                    );
                }
            }
        } else {
            dispatch(setCurrentToast({ id: "SceneOverride", message: "You can not override the tutorial scenes" }));
        }
    }

    function handleMediaCanvasDropped(canvasMedia: IDragOptions) {
        dispatch(
            setCurrentHotspot({
                currentHotspot: undefined,
                open: false,
            }),
        );
        dispatch(setCurrentlyPreview(false));
        ReactGA.send({ hitType: "canvasDrop", page: "/creator", title: `${canvasMedia.mediaType} added to canvas` });
        if (
            canvasMedia.mediaType === EDraggableOptions.INFORMATION ||
            canvasMedia.mediaType === EDraggableOptions.MOVEMENT ||
            canvasMedia.mediaType === EDraggableOptions.MOVEMENT_3D ||
            canvasMedia.mediaType === EDraggableOptions.VIDEO ||
            canvasMedia.mediaType === EDraggableOptions.ANSWER ||
            canvasMedia.mediaType === EDraggableOptions.INTERFACE ||
            canvasMedia.mediaType === EDraggableOptions.IMAGE ||
            canvasMedia.mediaType === EDraggableOptions.IMMERSIVE_MODEL ||
            canvasMedia.mediaType === EDraggableOptions.COMPLETE ||
            canvasMedia.mediaType === EDraggableOptions.TEXT_HOTSPOT
        ) {
            setHotspotLoading("CreateHotspot");
            const newHotspot = blankHotspot(canvasMedia.mediaType, canvasMedia.src);

            createHotspot({ sceneId: currentScene.id, hotspot: newHotspot });
            dispatch(setCurrentAttachedHotspot(newHotspot));
            dispatch(setHotspotAdded(newHotspot));

            if (canvasMedia.mediaType !== EDraggableOptions.INTERFACE) {
                dispatch(setCurrentHotspot({ currentHotspot: newHotspot, open: false }));
            }
            if (canvasMedia.mediaType === EDraggableOptions.IMAGE) {
                updateAllScenarioMedia({
                    id: currentScenarioId!,
                    media_type: EDraggableOptions.IMAGE,
                    media_ids: [canvasMedia.src.split("images/")[1].split(".")[0]],
                });
            } else if (canvasMedia.mediaType === EDraggableOptions.VIDEO) {
                updateAllScenarioMedia({
                    id: currentScenarioId!,
                    media_type: EDraggableOptions.VIDEO,
                    media_ids: [canvasMedia.src.split("video/")[1].split(".")[0]],
                });
            }
        } else if (canvasMedia.mediaType === EDraggableOptions.TEMPLATE && currentHotspotTransfer) {
            setHotspotLoading("CreateHotspot");
            createHotspot({ sceneId: currentScene.id, hotspot: currentHotspotTransfer.hotspot });
            dispatch(setCurrentAttachedHotspot(currentHotspotTransfer.hotspot));
            dispatch(setHotspotAdded(currentHotspotTransfer.hotspot));
            dispatch(setCurrentHotspotTransfer(undefined));
        } else if (canvasMedia.mediaType === EDraggableOptions.AUDIO) {
            updateScene({ ...currentScene, audio: canvasMedia.src });
            updateAllScenarioMedia({
                id: currentScenarioId!,
                media_type: EDraggableOptions.AUDIO,
                media_ids: [canvasMedia.src.split("audio/")[1].split(".")[0]],
            });
        } else {
            handleSceneReplace(canvasMedia);
        }
    }

    function handleMediaTimelineDropped(timelineMedia: IDragOptions) {
        ReactGA.send({
            hitType: "timelineDrop",
            page: "/creator",
            title: `${timelineMedia.mediaType} added to timeline`,
        });

        dispatch(setCurrentCreatorScenesTimelineShouldScrollToEnd(true));
        window.setTimeout(() => {
            dispatch(setCurrentCreatorScenesTimelineShouldScrollToEnd(false));
        }, 150);

        if (timelineMedia.mediaType === EDraggableOptions.IMMERSIVE_VIDEO) {
            const draggedVideo: IMediaItem | undefined = immersiveVideos?.find(
                (videosFindElement: IMediaItem) => videosFindElement.id === currentDragItemId,
            );

            const newScene = blankScene({
                newSrc: timelineMedia.src,
                type: timelineMedia.mediaType,
                id: uuid(),
                orderId: scenes!.length,
                previewName: draggedVideo ? draggedVideo.name : "New Scene ",
                //@ts-ignore
                startLocation: draggedVideo?.startLocation!,
            });
            dispatch(setCurrentlyPreview(false));
            setSceneLoading("CreateScene");
            createScene({ scenarioId: currentScenarioId!, scene: newScene });
            updateAllScenarioMedia({
                id: currentScenarioId!,
                media_type: EDraggableOptions.IMMERSIVE_VIDEO,
                media_ids: [timelineMedia.src.split("three_sixty_video/")[1].split(".")[0].split("/")[0]],
            });
            setChangeSceneBuffer(newScene.id);
        } else if (timelineMedia.mediaType === EDraggableOptions.IMMERSIVE_IMAGE) {
            const draggedImage: IMediaItem | undefined = immersiveImages?.find(
                (imageFindElement: IMediaItem) => imageFindElement.id === currentDragItemId,
            );

            const newScene = blankScene({
                newSrc: timelineMedia.src,
                type: timelineMedia.mediaType,
                id: uuid(),
                orderId: scenes!.length,
                previewName: draggedImage ? draggedImage.name : "New Scene ",
                //@ts-ignore
                startLocation: draggedImage?.startLocation!,
            });
            dispatch(setCurrentlyPreview(false));
            setSceneLoading("CreateScene");
            createScene({ scenarioId: currentScenarioId!, scene: newScene });
            if (timelineMedia.src !== "https://medias3.digi-sim-api.co.uk/app_defaults/default_360_image") {
                updateAllScenarioMedia({
                    id: currentScenarioId!,
                    media_type: EDraggableOptions.IMMERSIVE_IMAGE,
                    media_ids: [timelineMedia.src.split("three_sixty_image/")[1].split(".")[0]],
                });
            }
            setChangeSceneBuffer(newScene.id);
        } else if (timelineMedia.mediaType === EDraggableOptions.VIDEO) {
            const draggedVideo: IMediaItem | undefined = videos?.find(
                (videoFindElement: IMediaItem) => videoFindElement.id === currentDragItemId,
            );

            const newScene = blankScene({
                newSrc:
                    timelineMedia.src.split(".mp4")[0] +
                    "/" +
                    timelineMedia.src.split(".mp4")[0].split("video/")[1] +
                    ".mp4",
                type: timelineMedia.mediaType,
                id: uuid(),
                orderId: scenes!.length,
                previewName: draggedVideo ? draggedVideo.name : "New Scene ",
            });

            dispatch(setCurrentlyPreview(false));
            setSceneLoading("CreateScene");
            createScene({ scenarioId: currentScenarioId!, scene: newScene });
            updateAllScenarioMedia({
                id: currentScenarioId!,
                media_type: EDraggableOptions.VIDEO,
                media_ids: [timelineMedia.src.split("video/")[1].split(".")[0]],
            });
            setChangeSceneBuffer(newScene.id);
        }
    }

    function handleMediaQuestionCanvasDropped(canvasMedia: IDragOptions) {
        dispatch(
            setCurrentHotspot({
                currentHotspot: undefined,
                open: false,
            }),
        );
        dispatch(setCurrentlyPreview(false));
        ReactGA.send({
            hitType: "questionCanvasDrop",
            page: "/creator",
            title: `${canvasMedia.mediaType} added to question canvas`,
        });

        if (
            canvasMedia.mediaType === EDraggableOptions.INFORMATION ||
            canvasMedia.mediaType === EDraggableOptions.MOVEMENT ||
            canvasMedia.mediaType === EDraggableOptions.MOVEMENT_3D ||
            canvasMedia.mediaType === EDraggableOptions.VIDEO ||
            canvasMedia.mediaType === EDraggableOptions.ANSWER ||
            canvasMedia.mediaType === EDraggableOptions.INTERFACE ||
            canvasMedia.mediaType === EDraggableOptions.IMAGE ||
            canvasMedia.mediaType === EDraggableOptions.IMMERSIVE_MODEL ||
            canvasMedia.mediaType === EDraggableOptions.COMPLETE
        ) {
            const newHotspot = blankHotspot(canvasMedia.mediaType, canvasMedia.src);
            createQuestionHotspot({ groupId: currentQuestion!.id, hotspot: newHotspot });

            if (canvasMedia.mediaType !== EDraggableOptions.INTERFACE) {
                dispatch(setCurrentAttachedHotspot(newHotspot));
                dispatch(setHotspotAdded(newHotspot));
                dispatch(setCurrentHotspot({ currentHotspot: newHotspot, open: false }));
            }
            if (canvasMedia.mediaType === EDraggableOptions.IMAGE) {
                updateAllScenarioMedia({
                    id: currentScenarioId!,
                    media_type: EDraggableOptions.IMAGE,
                    media_ids: [canvasMedia.src.split("images/")[1].split(".")[0]],
                });
            } else if (canvasMedia.mediaType === EDraggableOptions.VIDEO) {
                updateAllScenarioMedia({
                    id: currentScenarioId!,
                    media_type: EDraggableOptions.VIDEO,
                    media_ids: [canvasMedia.src.split("video/")[1].split(".")[0]],
                });
            }
        } else if (canvasMedia.mediaType === EDraggableOptions.AUDIO) {
            updateHotspot({
                ...currentQuestion,
                contents: {
                    ...currentQuestion.contents,
                    preClickContent: {
                        ...currentQuestion.contents.preClickContent,
                        // @ts-ignore
                        question: { ...currentQuestion.contents.preClickContent.question, audio: canvasMedia.src },
                    },
                },
            });
            updateAllScenarioMedia({
                id: currentScenarioId!,
                media_type: EDraggableOptions.AUDIO,
                media_ids: [canvasMedia.src.split("audio/")[1].split(".")[0]],
            });
            dispatch(setCurrentAudio(canvasMedia.src));
            dispatch(setQuestionAudioAdd(canvasMedia.src));
        } else {
            handleSceneReplace(canvasMedia);
        }
    }

    const getReorderedScenesListByIndex = (result: DropResult): IScene[] => {
        // console.log("scenes listz", scenes);
        let listCopy = Array.from(scenes!);
        listCopy.sort((a: IScene, b: IScene) => {
            return a.order! - b.order!;
        });
        let listItem = listCopy.splice(result.source.index, 1)[0];
        listCopy.splice(result.destination!.index, 0, listItem);

        return listCopy;
    };

    async function onDragEnd(result: DropResult) {
        if (!result.destination) {
            dispatch(setCurrentDragging(undefined)); // if cancel drag make sure to not show drop containers
            return;
        }

        const { droppableId } = result.destination;

        // rich text editor normal mode drop (append/insert to end of content editable div)
        if (droppableId === EDroppableContainerId.RICH_TEXT_EDITOR_NORMAL_MODE_DROP) {
            const mediaSrcPath = result.draggableId;

            const mediaType = getMediaTypeFromFilePath(mediaSrcPath);

            if (mediaType === EMediaFileType.IMAGE_2D) {
                moveCursorToEnd(currentRichTextEditorNormalModeId!);
                document.execCommand("insertHTML", false, `<img src="${mediaSrcPath}" draggable="true"/>`);
                moveCursorToEnd(currentRichTextEditorNormalModeId!);

                updateAllScenarioMedia({
                    id: currentScenarioId!,
                    media_type: EDraggableOptions.IMAGE,
                    media_ids: [mediaSrcPath.split("images/")[1].split(".")[0]],
                });
            }

            if (mediaType === EMediaFileType.VIDEO_2D) {
                moveCursorToEnd(currentRichTextEditorNormalModeId!);
                document.execCommand(
                    "insertHTML",
                    false,
                    `<video style="user-select: auto;" width="460" id="#" controls="false" draggable="true"> <source src="${
                        mediaSrcPath!.split(".mp4")[0] +
                        "/" +
                        mediaSrcPath!.split(".mp4")[0].split("video/")[1] +
                        ".mp4"
                    }" type="video/mp4"> </video><br/>`,
                );
                moveCursorToEnd(currentRichTextEditorNormalModeId!);

                updateAllScenarioMedia({
                    id: currentScenarioId!,
                    media_type: EDraggableOptions.VIDEO,
                    media_ids: [mediaSrcPath.split("video/")[1].split(".")[0]],
                });
            }

            if (mediaType === EMediaFileType.AUDIO) {
                moveCursorToEnd(currentRichTextEditorNormalModeId!);
                document.execCommand(
                    "insertHTML",
                    false,
                    `<div><div style="display:inline-block;"> <audio controls="false" src="${mediaSrcPath}" draggable="true"/> </div><br></div>`,
                );
                moveCursorToEnd(currentRichTextEditorNormalModeId!);

                updateAllScenarioMedia({
                    id: currentScenarioId!,
                    media_type: EDraggableOptions.AUDIO,
                    media_ids: [mediaSrcPath.split("audio/")[1].split(".")[0]],
                });
            }
        }

        // rich text editor presentation mode drop (dispatch/set the object data to be dropped)
        if (droppableId === EDroppableContainerId.RICH_TEXT_EDITOR_PRESENTATION_MODE_DROP) {
            if (result.draggableId === EDraggableHotspotId.TEXT_HOTSPOT_PARAGRAPH) {
                // console.log("text drop");
                const startingText = "Paragraph";
                const textBoxWidthForDrop = 225;
                let textBoxHeightForDrop = await getTextBoxHeightBeforeDrop(startingText);
                dispatch(
                    setCurrentRichTextEditorPresentationModeDataToDropObject({
                        mediaType: EContentDataType.HTML,
                        content: startingText,
                        dropOffsetCoords: {
                            x: currentRichTextEditorPresentationModeDropOffsetsCoords!.x - textBoxWidthForDrop / 2,
                            y: currentRichTextEditorPresentationModeDropOffsetsCoords!.y - textBoxHeightForDrop / 2,
                        },
                        width: textBoxWidthForDrop,
                        height: textBoxHeightForDrop,
                    }),
                );
            } else {
                const mediaSrcPath = result.draggableId;
                const mediaType = getMediaTypeFromFilePath(mediaSrcPath);

                // console.log("media type", mediaType);
                // console.log("exp", mediaType === EMediaFileType.VIDEO_2D);
                // console.log("media src path", mediaSrcPath);

                if (mediaType === EMediaFileType.IMAGE_2D) {
                    const imgDimensions = await getImageDimensionsBeforeDrop(mediaSrcPath, 100);

                    dispatch(
                        setCurrentRichTextEditorPresentationModeDataToDropObject({
                            mediaType: EContentDataType.IMAGE,
                            content: mediaSrcPath,
                            dropOffsetCoords: {
                                x: currentRichTextEditorPresentationModeDropOffsetsCoords!.x - imgDimensions.width / 2,
                                y: currentRichTextEditorPresentationModeDropOffsetsCoords!.y - imgDimensions.height / 2,
                            },
                            width: imgDimensions.width,
                            height: imgDimensions.height,
                        }),
                    );
                } else if (mediaType === EMediaFileType.VIDEO_2D) {
                    // code for dropping video 2d media into presentation mode
                    const uuidForVideoResource = extractUUIDFromPath(mediaSrcPath);

                    if (uuidForVideoResource) {
                        const videoResourcePath = `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/video/${uuidForVideoResource}/${uuidForVideoResource}.mp4`;

                        let videoDimensions = await getVideoDimensionsBeforeDrop(videoResourcePath);

                        const videoWidthOnDrop = 250;

                        let videoHeightOnDrop = getPreserveRatioHeightGivenWidth(
                            videoDimensions.height,
                            videoDimensions.width,
                            videoWidthOnDrop,
                        );

                        dispatch(
                            setCurrentRichTextEditorPresentationModeDataToDropObject({
                                mediaType: EContentDataType.VIDEO,
                                content: videoResourcePath,
                                dropOffsetCoords: {
                                    x: currentRichTextEditorPresentationModeDropOffsetsCoords!.x - videoWidthOnDrop / 2,
                                    y: currentRichTextEditorPresentationModeDropOffsetsCoords!.y - videoHeightOnDrop / 2,
                                },
                                width: videoWidthOnDrop,
                                height: videoHeightOnDrop,
                            }),
                        );
                    } else {
                        throw new Error("couldn't extract uuid for video 2d from media src path");
                    }
                } else if (mediaType === EMediaFileType.AUDIO) {
                    dispatch(
                        setCurrentRichTextEditorPresentationModeDataToDropObject({
                            mediaType: EContentDataType.AUDIO,
                            content: mediaSrcPath,
                            dropOffsetCoords: {
                                x: currentRichTextEditorPresentationModeDropOffsetsCoords!.x - 220 / 2,
                                y: currentRichTextEditorPresentationModeDropOffsetsCoords!.y - 72 / 2,
                            },
                            width: 220,
                            height: 72,
                        }),
                    );
                }
            }
        }

        if (droppableId === EDroppableContainerId.CREATOR_SCENES_TIMELINE) {
            if (result.source.index !== result.destination.index) {
                const scenesInOrderByIndex = getReorderedScenesListByIndex(result);
                const scenesInOrderByOrderProperty = scenesInOrderByIndex.map((scene: IScene, i: number) => {
                    return {
                        ...scene,
                        order: i,
                    };
                });

                setSceneLoading("ReorderScenes");
                let promises = scenesInOrderByOrderProperty.map((scene: IScene) => updateScene(scene).unwrap());
                let results = await Promise.all(promises);
                await sleep(250);
                setSceneLoading("");
            }
        }

        if (droppableId === EDroppableContainerId.CANVAS_DROP) {
            handleMediaCanvasDropped(currentlyDragging!);
        }

        if (droppableId === EDroppableContainerId.QUESTION_CANVAS_DROP) {
            handleMediaQuestionCanvasDropped(currentlyDragging!);
        }

        if (droppableId === EDroppableContainerId.SCENES_TIMELINE_DROP) {
            handleMediaTimelineDropped(currentlyDragging!);
        }

        if (droppableId === EDroppableContainerId.SCENARIO_PREVIEW_IMAGE_DROP) {
            dispatch(setCurrentPreviewImage(result.draggableId));
        }

        if (
            droppableId === EDroppableContainerId.HOTSPOT_FEEDBACK_IMAGE_DROP ||
            droppableId === EDroppableContainerId.HOTSPOT_FEEDBACK_VIDEO_DROP ||
            droppableId === EDroppableContainerId.HOTSPOT_FEEDBACK_AUDIO_DROP
        ) {
            if (currentlyDragging) {
                dispatch(
                    setEditHotspotDialogMediaTransfer({
                        src: currentlyDragging.src,
                        type: currentlyDragging.mediaType,
                    }),
                );
            }
        }

        dispatch(setCurrentDragging(undefined));
    }

    function onDragStart(start: DragStart) {
        if (!start.source) return;

        // 360 images
        if (start.source.droppableId === EDroppableContainerId.MEDIA_360_IMAGES) {
            const tempSplitList = start.draggableId.split("/");
            setCurrentDragItemId(tempSplitList[tempSplitList.length - 1]);
            dispatch(
                setCurrentDragging({
                    mediaType: EDraggableOptions.IMMERSIVE_IMAGE,
                    src: start.draggableId,
                    // preview: preview,
                    preview: undefined,
                }),
            );
        }

        // 360 videos
        if (start.source.droppableId === EDroppableContainerId.MEDIA_360_VIDEOS) {
            const tempSplitList = start.draggableId.split("/");
            setCurrentDragItemId(tempSplitList[tempSplitList.length - 1].split(".")[0]);
            dispatch(
                setCurrentDragging({
                    mediaType: EDraggableOptions.IMMERSIVE_VIDEO,
                    src: start.draggableId,
                    // preview: preview,
                    preview: undefined,
                }),
            );
        }

        // 2d images
        if (start.source.droppableId === EDroppableContainerId.MEDIA_2D_IMAGES) {
            dispatch(
                setCurrentDragging({
                    mediaType: EDraggableOptions.IMAGE,
                    src: start.draggableId,
                    // preview: preview,
                    preview: undefined,
                }),
            );
        }

        // 2d videos
        if (start.source.droppableId === EDroppableContainerId.MEDIA_2D_VIDEOS) {
            const tempSplitList = start.draggableId.split("/");
            setCurrentDragItemId(tempSplitList[tempSplitList.length - 1].split(".")[0]);
            dispatch(
                setCurrentDragging({
                    mediaType: EDraggableOptions.VIDEO,
                    src: start.draggableId,
                    // preview: preview,
                    preview: undefined,
                }),
            );
        }

        // audio
        if (start.source.droppableId === EDroppableContainerId.MEDIA_AUDIO) {
            dispatch(
                setCurrentDragging({
                    mediaType: EDraggableOptions.AUDIO,
                    src: start.draggableId,
                }),
            );
        }

        // 360 images

        if (start.draggableId && start.draggableId.includes("template")) {
            dispatch(
                setCurrentDragging({
                    mediaType: EDraggableOptions.TEMPLATE,
                    src: start.source.droppableId,
                }),
            );
        }

        if (start.source.droppableId === EDroppableContainerId.HOTSPOTS) {
            switch (start.draggableId) {
                case EDraggableHotspotId.INFO_HOTSPOT:
                    dispatch(
                        setCurrentDragging({
                            mediaType: EDraggableOptions.INFORMATION,
                            src: `${process.env.PUBLIC_URL}/icons/info.svg`,
                        }),
                    );
                    break;
                case EDraggableHotspotId.MOVEMENT_HOTSPOT:
                    dispatch(
                        setCurrentDragging({
                            mediaType: EDraggableOptions.MOVEMENT,
                            src: `${process.env.PUBLIC_URL}/icons/movement.svg`,
                        }),
                    );
                    break;

                case EDraggableHotspotId.INVISIBLE_HOTSPOT:
                    dispatch(
                        setCurrentDragging({
                            mediaType: EDraggableOptions.ANSWER,
                            src: `invisible`,
                        }),
                    );
                    break;
                case EDraggableHotspotId.BLANK_ANSWER_HOTSPOT:
                    dispatch(
                        setCurrentDragging({
                            mediaType: EDraggableOptions.ANSWER,
                            src: `blank`,
                        }),
                    );
                    break;
                case EDraggableHotspotId.CORRECT_ANSWER_HOTSPOT:
                    dispatch(
                        setCurrentDragging({
                            mediaType: EDraggableOptions.ANSWER,
                            src: `correct`,
                        }),
                    );
                    break;

                case EDraggableHotspotId.INCORRECT_ANSWER_HOTSPOT:
                    dispatch(
                        setCurrentDragging({
                            mediaType: EDraggableOptions.ANSWER,
                            src: `incorrect`,
                        }),
                    );
                    break;
                case "continue":
                    dispatch(
                        setCurrentDragging({
                            mediaType: EDraggableOptions.ANSWER,
                            src: `continue`,
                        }),
                    );
                    break;
                case EDraggableHotspotId.COMPLETE_SCENARIO_HOTSPOT:
                    dispatch(
                        setCurrentDragging({
                            mediaType: EDraggableOptions.COMPLETE,
                            src: `incorrect`,
                        }),
                    );
                    break;
                case EDraggableHotspotId.TEMPLATE_HOTSPOT:
                    dispatch(
                        setCurrentDragging({
                            mediaType: EDraggableOptions.TEMPLATE,
                            src: ``,
                        }),
                    );
                    break;
                case EDraggableHotspotId.TEXT_HOTSPOT_PARAGRAPH:
                    dispatch(
                        setCurrentDragging({
                            mediaType: EDraggableOptions.TEXT_HOTSPOT,
                            src: `paragraph`,
                        }),
                    );
                    break;
            }
        }
    }

    // function onDragUpdated(update: DragUpdate) {
    // console.log("ON DRAG UPDATED", update);
    // }

    //  function onBeforeCapture  (before: BeforeCapture)  {
    // dispatch(setCurrentDragging({ mediaType: EDraggableOptions.IMMERSIVE_IMAGE, src: "" }));
    // };

    // function OnBeforeDragStartResponder(start: DragStart) {}

    return (
        <DragDropContext
            onDragUpdate={(dragUpdate) => {
                const { source } = dragUpdate;
                if (source.droppableId === EDroppableContainerId.CREATOR_SCENES_TIMELINE) {
                    playSoundEffect(shuffleSound, 0.07);
                }
            }}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            // onDragUpdate={onDragUpdated}
            // onBeforeCapture={onBeforeCapture}
            // onBeforeDragStart={OnBeforeDragStartResponder}
        >
            {children}
        </DragDropContext>
    );
}
